<template>
  <section class="container mt-3">
    <div class="columns is-centered">
      <div class="column is-three-quarters">
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item is-clickable">
              <b-button @click="goBackinHistory" type="is-light">
                <strong>
                  <span class="icon">
                    <i class="fas fa-lg fa-arrow-left"></i>
                  </span>
                </strong>
              </b-button>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="columns is-centered has-text-left mt-10">
      <div class="column is-three-quarters">
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">From</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  id="from"
                  ref="from"
                  type="email"
                  @input="updateFrom"
                  :value="from"
                  required
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">To</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  id="to"
                  ref="to"
                  type="email"
                  :value="$route.query.e"
                  required
                  disabled
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Reply To</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select v-model="replyTo">
                <option
                    :value="agent.email"
                >
                    {{ agent.email }}
                </option>
                <option
                    :value="from"
                >
                    {{ from }}
                </option>
            </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Subject</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  id="subject"
                  ref="subject"
                  type="text"
                  minlength="2"
                  maxlength="70"
                  @input="updateSubject"
                  required
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Body</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input @input="updateBody" id="body" ref="body" type="textarea" ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal has-text-centered mt-3">
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-button
                  @click="sendEmail"
                  expanded
                  icon-right="arrow-circle-right"
                  type="is-success"
                  >Send Email</b-button
                >
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createSnackbar } from '@/common';

export default {
  name: 'MailModel',
  data() {
    return {
      sent: false,
      subject: '',
      from: 'support@constructionworkersupport.com',
      body: '',
      replyTo: '',
    };
  },
  computed: {
    ...mapState({
      agent: ({ agentData: { agent } }) => agent,
    }),
  },
  mounted() {
    this.replyTo = this.agent.email;
  },
  methods: {
    ...mapActions(['sendCustomEmail']),
    createSnackbar,
    updateSubject(e) {
      this.subject = e;
    },
    updateFrom(e) {
      this.from = e;
    },
    updateReplyTo(e) {
      this.replyTo = e;
    },
    updateBody(e) {
      this.body = e;
    },
    goBackinHistory() {
      this.$router.go(-1);
    },
    async sendEmail() {
      try {
        await this.sendCustomEmail({
          subject: this.subject,
          from: this.from,
          body: this.body,
          replyTo: this.replyTo,
        });
        this.sent = true;
        return this.createSnackbar('Email has been sent', true);
      } catch (error) {
        window.scrollTo(0, 0);
        return this.createSnackbar(error.message);
      }
    },
  },
};
</script>

<style scoped>
    .mt-10 {
        margin-top: 3.5em;
    }

    .mt-3 {
        margin-top: 1em;
    }
</style>
